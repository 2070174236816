.App {
  text-align: center;
  background-color: #020617;
  color: #E2E8F0;
  font-family: Arial, sans-serif;
  min-height: 100vh;
}

.hero {
  padding: 80px 20px;
  background: radial-gradient(circle at 50% 50%, rgba(99, 255, 218, 0.15) 0%, rgba(10, 25, 47, 0) 50%);
}

.badge {
  display: inline-block;
  padding: 8px 16px;
  background-color: rgba(100, 255, 218, 0.1);
  color: #64FFDA;
  border-radius: 20px;
  margin-bottom: 20px;
  font-size: 14px;
}

h1 {
  font-size: 64px;
  margin: 20px 0;
  font-weight: bold;
}

.accent {
  color: #64FFDA;
}

.hero-text {
  font-size: 24px;
  max-width: 800px;
  margin: 20px auto;
}

.subtitle {
  color: #94A3B8;
  font-size: 18px;
}

section {
  padding: 60px 20px;
}

.team-grid, .features-grid, .tiers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.team-card, .feature-card, .tier-card, .record-card {
  background: rgba(30, 41, 59, 0.5);
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 20px;
  padding: 30px;
  transition: transform 0.3s ease;
}

.team-card:hover, .feature-card:hover, .tier-card:hover {
  transform: translateY(-5px);
}

.team-image-container {
  width: 200px;
  height: 200px;
  margin: 0 auto 20px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.team-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  color: #64FFDA;
  margin: 10px 0;
  font-size: 18px;
}

.divider {
  height: 1px;
  width: 100px;
  background: linear-gradient(to right, transparent, rgba(100, 255, 218, 0.3), transparent);
  margin: 20px auto;
}

.description {
  color: #94A3B8;
  line-height: 1.6;
  font-size: 16px;
}

.number {
  display: block;
  font-size: 48px;
  color: rgba(100, 255, 218, 0.1);
  margin-bottom: 15px;
  font-weight: bold;
}

.tier-number {
  display: block;
  font-size: 36px;
  color: #64FFDA;
  margin-bottom: 20px;
  font-weight: bold;
}

.tier-card ul {
  list-style: none;
  padding: 0;
}

.tier-card li {
  margin: 15px 0;
  position: relative;
  padding-left: 20px;
  text-align: left;
}

.tier-card li:before {
  content: "•";
  color: #64FFDA;
  position: absolute;
  left: 0;
}

.record-card {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

.cta-section {
  padding: 40px 20px;
}

.cta-button {
  background: linear-gradient(to right, #64FFDA, #4CD7BD);
  border: none;
  padding: 16px 32px;
  font-size: 18px;
  border-radius: 10px;
  color: #020617;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: scale(1.05);
}

footer {
  border-top: 1px solid rgba(148, 163, 184, 0.1);
  padding: 20px;
  color: #64748B;
}

@media (max-width: 768px) {
  h1 {
    font-size: 48px;
  }
  
  .hero-text {
    font-size: 20px;
  }
  
  .team-grid, .features-grid, .tiers-grid {
    grid-template-columns: 1fr;
  }
  
  .team-image-container {
    width: 150px;
    height: 150px;
  }
  
  .number {
    font-size: 36px;
  }
}

.features::before, .why-different::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(rgba(100, 255, 218, 0.02) 1px, transparent 1px),
    linear-gradient(90deg, rgba(100, 255, 218, 0.02) 1px, transparent 1px);
  background-size: 30px 30px;
  pointer-events: none;
}

.accent:hover {
  text-shadow: 0 0 10px rgba(100, 255, 218, 0.5);
}

.track-record-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.multiplier {
  font-size: 36px;
  font-weight: bold;
  margin: 20px 0;
}

.record-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.quadrant-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.quadrant-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 2rem;
  transition: all 0.3s ease;
}

.quadrant-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--accent-color);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.emoji {
  font-size: 1.5rem;
}

.card-subtitle {
  color: var(--accent-color);
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature-list li {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.highlight {
  color: var(--accent-color);
  font-weight: 600;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .quadrant-grid {
    grid-template-columns: 1fr;
  }
}